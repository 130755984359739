import DeleteIcon from "@mui/icons-material/Delete";
import CloseIcon from "@mui/icons-material/Close";
import DownloadIcon from "@mui/icons-material/Download";
import SearchIcon from "@mui/icons-material/Search";
import { Box, Button, IconButton, Input, TextField, Typography } from "@mui/material";
import axios from "axios";
import moment from "moment";
import React, { useCallback, useEffect, useRef, useState } from "react";
import DataTableItem from "react-data-table-component";
import { useNavigate, useParams, useSearchParams } from "react-router-dom";
import { baseUrl } from "../../utils/constants";
import localizationStrings from "../../utils/localizations";
import RichTextEditor from "react-rte";

// const columns = ;

const toolbarConfig = {
  // Optionally specify the groups to display (displayed in the order listed).
  display: ["INLINE_STYLE_BUTTONS", "BLOCK_TYPE_BUTTONS", "BLOCK_TYPE_DROPDOWN", "HISTORY_BUTTONS"],
  INLINE_STYLE_BUTTONS: [
    { label: "Bold", style: "BOLD" },
    { label: "Italic", style: "ITALIC" },
    { label: "Strikethrough", style: "STRIKETHROUGH" },
    { label: "Monospace", style: "CODE" },
    { label: "Underline", style: "UNDERLINE" },
  ],
  BLOCK_TYPE_DROPDOWN: [
    { label: "Normal", style: "unstyled" },
    { label: "Heading Large", style: "header-one" },
    { label: "Heading Medium", style: "header-two" },
    { label: "Heading Small", style: "header-three" },
    { label: "Code Block", style: "code-block" },
  ],
  BLOCK_TYPE_BUTTONS: [
    { label: "UL", style: "unordered-list-item" },
    { label: "OL", style: "ordered-list-item" },
    { label: "Blockquote", style: "blockquote" },
  ],
};

export default function Tafheem() {
  const [buttonLoading, setButtonLoading] = useState(false);
  const [loading, setLoading] = useState("");
  const params = useParams();
  const [title, setTitle] = useState(params.title);
  const [id, setId] = useState("");
  const [val, setVal] = useState(RichTextEditor.createEmptyValue());
  const [left, setLeft] = useState();
  const [right, setRight] = useState();

  const [, reload] = useState({});

  const getTafheem = async (silent = false) => {
    if (!silent) setLoading(true);
    try {
      const data = await axios.get(baseUrl + "tafheem/title/" + encodeURIComponent(title));
      if (data.data.success) {
        // setTitle(data.data.data);
        // setContents(data.data.data.contents);
        setVal(RichTextEditor.createValueFromString(data.data.data.contents, "markdown"));
        setId(data.data.data._id);
        if (data.data.data.left) {
          setLeft(RichTextEditor.createValueFromString(data.data.data.left, "markdown"));
        } else {
          setLeft();
        }
        if (data.data.data.right) {
          setRight(RichTextEditor.createValueFromString(data.data.data.right, "markdown"));
        } else {
          setRight();
        }
      }
      if (!silent) setLoading(false);
    } catch (e) {
      if (!silent) setLoading(false);
      console.warn(e);
    }
  };
  useEffect(() => {
    console.log("Here");
    getTafheem();
  }, [title]);
  useEffect(() => {
    console.log("Here");
    if (params.title && params.title != title) {
      setTitle(params.title);
    }
  }, [params]);

  const onSave = async (silent = false) => {
    if (!silent) setButtonLoading(true);
    try {
      const data = id
        ? await axios.put(baseUrl + "tafheem/" + id, {
            contents: val.toString("markdown"),
            left: left?.toString("markdown"),
            right: right?.toString("markdown"),
          })
        : await axios.post(baseUrl + "tafheem/", { contents: val.toString("markdown"), title: title });

      if (data.data.success) {
        getTafheem();
        // alert("Saved Successfully");
      } else {
        alert(data.data?.message || "Something went wrong!");
      }
      if (!silent) setButtonLoading(false);
    } catch (e) {
      if (!silent) setButtonLoading(false);
      alert(e?.response?.data?.message || e?.message || "Something went wrong");
      console.warn(e);
    }
  };

  return (
    <div style={{ height: "calc(100vh - 56px)", width: "100%", padding: 30 }}>
      {loading ? (
        <div className="loader"></div>
      ) : (
        <Box style={{ flex: 1, display: "flex", flexDirection: "column" }}>
          {/* Delete Button */}
          {/* <Button
            style={{ color: "black" }}
            variant="outlined"
            startIcon={<DeleteIcon />}
            onClick={() => {
              if (window.confirm("Are you sure you want to delete!")) {
                setLoading(true);
                axios
                  .delete(baseUrl + "tafheem/" + id)
                  .then((rslt) => {
                    if (!rslt.data.success) {
                      alert(rslt.message || "Something went wrong");
                    } else {
                      window.location.reload();
                    }
                  })
                  .catch((err) => {
                    alert(err.response?.data?.message || err.message || "Error: Unable to delete");
                    setLoading(false);
                  });
              }
            }}
          >
            {localizationStrings.Delete}
          </Button> */}
          <div style={{ height: 20 }} />
          {/* <TextField
            label={localizationStrings.EnterYourMessage}
            multiline
            minRows={10}
            variant="filled"
            value={val.toString("markdown")}
            onChange={(e) => setVal(RichTextEditor.createValueFromString(e.target.value, "markdown"))}
          /> */}
          <RichTextEditor value={val} onChange={setVal} editorStyle={{ height: "40vh" }} toolbarConfig={toolbarConfig} />

          <div style={{ height: 20 }} />
          <div style={{ display: "flex", flexDirection: "row" }}>
            <div style={{ flex: 1 }}>
              {left && <RichTextEditor value={left} onChange={setLeft} editorStyle={{ height: "20vh" }} toolbarConfig={toolbarConfig} />}
            </div>
            <div style={{ width: 20 }} />
            <div style={{ flex: 1 }}>
              {right && <RichTextEditor value={right} onChange={setRight} editorStyle={{ height: "20vh" }} toolbarConfig={toolbarConfig} />}
            </div>
          </div>

          {(!!left || !!right) && <div style={{ height: 20 }} />}

          {buttonLoading ? (
            <div className="loader"></div>
          ) : (
            <Button onClick={() => onSave()} variant="contained">
              {localizationStrings.Save}
            </Button>
          )}
          <div style={{ height: 20 }} />
        </Box>
      )}
    </div>
  );
}
